import { useEffect } from "react";
import { nanoid } from "nanoid";

export const useUser = () => {
  useEffect(() => {
    const user_id = localStorage.getItem("user_id");
    if (!user_id) {
      localStorage.setItem("user_id", `us${nanoid()}`);
    }
  }, []);
};
