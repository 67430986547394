import { FC, useEffect, useState } from "react";
import { IArticle, ICompany, ITheme } from "../../types";
import { useNavigate, useParams } from "react-router-dom";
import { API } from "../../api";
import { Header } from "../../components/Header";

export const Articles = () => {
  const [articles, setArticles] = useState<ITheme[]>([]);
  const [company, setCompany] = useState<ICompany>();

  const { company_id } = useParams();
  const history = useNavigate();

  const getArticles = async () => {
    if (company_id) {
      const res = await API.getCompany(company_id);
      setCompany(res);
    }
  };

  const clickHandler = (id: string) => {
    history(id);
  };

  const ideaHandler = () => {
    history("idea");
  };

  useEffect(() => {
    getArticles();
  }, []);

  if (!company) return null

  return (
    <>
      {/* <h1>Книга учета доходов и расходов</h1> */}
      <h1>{company.name}</h1>
      <div>
        {company.themes.map((theme) => (
          <div key={theme.id} className="card">
            <h4>{theme.name}</h4>
            <div>
              {theme.articles.map((article) => (
                <div
                  className="article"
                  onClick={() => clickHandler(article.id)}
                >
                  {article.name}
                </div>
              ))}
            </div>
          </div>
        ))}
      </div>
      {/* <div>
        <div className="card" onClick={ideaHandler}>
          💡 Предложить идею
        </div>
      </div> */}
    </>
  );
};
