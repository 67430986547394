import { FC, useState } from "react";
import { API } from "../../api";
import { StepType } from "../../types";

interface ComplaintProps {
  article_id: string;
  step_id: number;
  setType?: (type: StepType) => void;
}

export const ComplaintBlock: FC<ComplaintProps> = ({
  article_id,
  step_id,
  setType,
}) => {
  const [text, setText] = useState("");
  const [email, setEmail] = useState("");
  const [isChecked, setIsChecked] = useState(false);

  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setIsChecked(event.target.checked);
  };

  const submitHandler = async () => {
    if (text.length > 5) {
      await API.addComplaint(article_id, step_id, text, email);
      setType && setType("gratitude");
    }
  };

  const backHandler = () => {
    setType && setType("step");
  };

  return (
    <div>
      <h3>Очень жаль</h3>
      <div className="text">
        Напишите, пожалуйста, в чем причина низкой оценки, и мы исправим
        инструкцию или продукт.
      </div>
      <textarea
        className="textarea"
        placeholder="Напишите, пожалуйста, в свободной форме. Это анонимно."
        rows={8}
        onChange={(e) => setText(e.target.value)}
        autoFocus
      />
      <div className="margin">
        <input
          type="checkbox"
          className="checkbox"
          checked={isChecked}
          id="myCheckbox"
          onChange={handleCheckboxChange}
        />
        <label htmlFor="myCheckbox">Хочу получить ответ на почту</label>
      </div>
      {isChecked && (
        <input
          placeholder="Куда отправить ответ"
          className="input margin"
          onChange={(e) => setEmail(e.target.value)}
        />
      )}

      <div className="buttons">
        <button className="success" onClick={submitHandler}>
          Отправить
        </button>
        {setType && (
          <button className="unclear" onClick={backHandler}>
            Назад
          </button>
        )}
      </div>
    </div>
  );
};
