import { useState } from "react";
import { API } from "../../api";
import { useNavigate, useParams } from "react-router-dom";

export const IdeaBlock = () => {
  const [text, setText] = useState("");
  const [email, setEmail] = useState("");
  const [isChecked, setIsChecked] = useState(false);

  const history = useNavigate();
  const { company_id } = useParams();

  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setIsChecked(event.target.checked);
  };

  const submitHandler = async () => {
    if (text.length > 5) {
        await API.addIdea(company_id!, text, email);
        history("/" + company_id);
      //   setType && setType("gratitude");
    }
  };

  const backHandler = () => {
    history("/" + company_id);
    // setType && setType("step");
  };

  return (
    <div>
      <h3>Моя идея</h3>
      <div className="text">
        Напишите, что хотелось бы добавить или изменить в продукте.
      </div>
      <textarea
        className="textarea"
        placeholder="Опишите в свободной форме вашу идею"
        rows={8}
        onChange={(e) => setText(e.target.value)}
        autoFocus
      />
      <div className="margin">
        <input
          type="checkbox"
          className="checkbox"
          checked={isChecked}
          id="myCheckbox"
          onChange={handleCheckboxChange}
        />
        <label htmlFor="myCheckbox">Хочу оставить почту для связи</label>
      </div>
      {isChecked && (
        <input
          placeholder="Почта"
          className="input margin"
          onChange={(e) => setEmail(e.target.value)}
        />
      )}

      <div className="buttons">
        <button className="success" onClick={submitHandler}>
          Отправить
        </button>
        <button className="unclear" onClick={backHandler}>
          Назад
        </button>
      </div>
    </div>
  );
};
