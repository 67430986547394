import React from "react";
import logo from "./logo.svg";
import "./App.css";
import { Route, Routes } from "react-router-dom";
import { Step } from "./pages/Step/Step";
import { Articles } from "./pages/Articles/Articles";
import { Main } from "./pages/Main/Main";
import { Layout } from "./components/Layout";
import { IdeaBlock } from "./pages/Feedback/IdeaBlock";

function App() {
  return (
    <>
      <Routes>
        <Route index element={<Main />} />
        <Route
          path="/:company_id"
          element={
            <Layout>
              <Articles />
            </Layout>
          }
        />
        <Route
          path="/:company_id/:article_id"
          element={
            <Layout>
              <Step />
            </Layout>
          }
        />
        <Route
          path="/:company_id/idea"
          element={
            <Layout>
              <IdeaBlock />
            </Layout>
          }
        />
      </Routes>
    </>
  );
}

export default App;
