import { FC } from "react";
import { getImageUrl } from "../utils/getImageUrl";
import { useNavigate, useParams } from "react-router-dom";

export const Header = () => {
  const history = useNavigate();
  const { company_id } = useParams();

  const logoClick = () => {
    history("/" + company_id);
  };

  return (
    <div className="header">
      <div className="logo_block" onClick={logoClick}>
        <img
          className="logo"
          src={getImageUrl("0ab2c716585f4c60db619ee55eae1bc7")}
        />
        <span className="logo_text">Все инструкции</span>
      </div>
    </div>
  );
};
