import axios from "axios";

axios.defaults.baseURL = process.env.NODE_ENV === 'production' ? "https://wizardhub.ru/api/" : "http://localhost:5118/api/";
axios.defaults.headers.common["Authorization"] = `Bearer ${
  localStorage.getItem("user") || localStorage.getItem("user_id")
}`;

export const API = {
  async getArticles(company_id: string) {
    try {
      const res = await axios.get("articles/" + company_id);
      return res.data;
    } catch (error) {
      console.log(error);
    }
  },
  async getCompany(company_id: string) {
    try {
      const res = await axios.get("articles/" + company_id);
      return res.data;
    } catch (error) {
      console.log(error);
    }
  },
  async getStep(article_id: string) {
    try {
      const res = await axios.get("steps/" + article_id);
      return res.data;
    } catch (error) {
      console.log(error);
    }
  },
  async getNewStep(article_id: string, current_step: number, next: number) {
    try {
      const res = await axios.post("steps", {
        article_id,
        step_id: next,
        from_step_id: current_step,
      });
      return res.data;
    } catch (error) {
      console.log(error);
    }
  },
  async addUnclear(article_id: string, current_step: number) {
    try {
      const res = await axios.post("steps/unclear", {
        article_id,
        from_step_id: current_step,
      });
      return res.data;
    } catch (error) {
      console.log(error);
    }
  },
  async addRating(article_id: string, rating: number) {
    try {
      const res = await axios.post("rating", {
        article_id,
        rating,
      });
      return res.data;
    } catch (error) {
      console.log(error);
    }
  },
  async addComplaint(
    article_id: string,
    step_id: number,
    text: string,
    email: string
  ) {
    try {
      const res = await axios.post("complaints", {
        article_id,
        step_id,
        text,
        email,
      });
      return res.data;
    } catch (error) {
      console.log(error);
    }
  },
  async addIdea(
    company_id: string,
    text: string,
    email: string
  ) {
    try {
      const res = await axios.post("ideas", {
        company_id,
        text,
        email,
      });
      return res.data;
    } catch (error) {
      console.log(error);
    }
  },
};
