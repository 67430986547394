import { FC, useState } from "react";
import { API } from "../../api";
import { Star } from "../../components/Star";
import { ComplaintBlock } from "./ComplaintBlock";
import { StepType } from "../../types";

interface SuccessProps {
  article_id: string;
  step_id: number;
  setType: (type: StepType) => void;
}

export const SuccessBlock: FC<SuccessProps> = ({ article_id, step_id, setType }) => {
  const [rating, setRating] = useState(0);
  const [isRating, setIsRating] = useState(true);

  const handleClick = (value: number) => {
    setRating(value);
  };

  const submitHandler = async () => {
    await API.addRating(article_id, rating);
    if (rating === 5) {
      setType('gratitude');
    } else {
      setType('complaint')
    }
  };

  if (isRating)
    return (
      <div>
        <h3>Успех</h3>
        <div>
          Оставьте, пожалуйста, оценку инструкции, насколько она была понятна и
          полезна.
        </div>
        {[1, 2, 3, 4, 5].map((value) => (
          <Star
            key={value}
            selected={value <= rating}
            onClick={() => handleClick(value)}
          />
        ))}
        <div className="buttons">
          {rating > 0 && (
            <button className="success" onClick={submitHandler}>
              Отправить
            </button>
          )}
        </div>
      </div>
    );

  return <ComplaintBlock article_id={article_id} step_id={step_id} />;
};
