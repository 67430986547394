import { FC } from "react";
import { useNavigate } from "react-router-dom";

interface Props {
  company_id: string;
}

export const GratitudeBlock: FC<Props> = ({ company_id }) => {
  const history = useNavigate();

  const clickHandler = () => {
    history('/' + company_id);
  };

  return (
    <div>
      <div>Спасибо за обратную связь!</div>
      <div className="buttons">
        <button onClick={clickHandler}>Другие инструкции</button>
      </div>
    </div>
  );
};
