import { useEffect, useState } from "react";
import { API } from "../../api";
import { useNavigate, useParams } from "react-router-dom";
import { useUser } from "../../hooks/useUser";
import { getImageUrl } from "../../utils/getImageUrl";
import { UnclearBlock } from "../Feedback/UnclearBlock";
import { GratitudeBlock } from "../Feedback/GratitudeBlock";
import { SuccessBlock } from "../Feedback/SuccessBlock";
import { Header } from "../../components/Header";
import { getBlockUrl } from "../../utils/getBlockUrl";
import { ComplaintBlock } from "../Feedback/ComplaintBlock";
import { IButton, IStep, StepType } from "../../types";
import ReactMarkdown from "react-markdown";

export const Step = () => {
  const [data, setData] = useState<IStep | null>(null);
  const [type, setType] = useState<StepType>("step");
  const [isFirst, setIsFirst] = useState<boolean>(false);

  const { article_id, company_id } = useParams();

  const history = useNavigate();

  const clickHandler = async (button: IButton) => {
    if (article_id && data) {
      if (button.next_article_id) {
        history(`/${company_id}/${button.next_article_id}`);
      } else {
        const res = await API.getNewStep(article_id, data?.id, button.next_step);
        setData(res);
        setIsFirst(false);
      }
    }
  };

  const setUnclear = async (id: number) => {
    setType("unclear");
  };

  const getStep = async () => {
    if (article_id) {
      const res = await API.getStep(article_id);
      setData(res);
    }
  };

  useUser();

  useEffect(() => {
    getStep();
    setIsFirst(true);
  }, [article_id]);

  if (!data) return null;

  if (type === "unclear" && article_id) {
    return <UnclearBlock article_id={article_id} step_id={data.id} setType={setType} />;
  }

  if (type === "complaint" && article_id) {
    return <ComplaintBlock article_id={article_id} step_id={data.id} setType={setType} />;
  }

  if (type === "gratitude" && company_id) {
    return <GratitudeBlock company_id={company_id} />;
  }

  if (data.type === "success" && article_id) {
    return <SuccessBlock article_id={article_id} step_id={data.id} setType={setType} />;
  }

  const shouldShowInformMessage = data.buttons.some((item) => item.type === "success") && isFirst;

  return (
    <div className="step_page">
      {shouldShowInformMessage && (
        <div className="info">
          ❗ После выполнения каждого шага нажимайте синию кнопку "Выполнено / Понятно" либо
          "Непонятно", если вы столкнулись со сложностями на этом шаге.{" "}
        </div>
      )}
      <h2>{data.heading}</h2>
      {data.content ? (
        <ReactMarkdown
          children={data.content}
          components={{
            img: ({ node, ...props }) => <img style={{ maxWidth: "100%" }} {...props} />,
            code: ({ node, ...props }) => (
              <code
                style={{ backgroundColor: "lightgoldenrodyellow", padding: "1px 3px", borderRadius: "1px" }}
                {...props}
              />
            ),
            h4: ({ node, ...props }) => <h4 style={{ marginBlockStart: "2em", marginBlockEnd: "0em" }} {...props} />,
          }}
        />
      ) : (
        data.blocks?.map((block) => {
          return block.type === "text" ? (
            <div key={block.id} className="text">
              {block.value}
            </div>
          ) : block.type === "important" ? (
            <div key={block.id} className="important">
              ☝️ {block.value}
            </div>
          ) : block.type === "url" ? (
            <div key={block.id}>
              <a href={getBlockUrl(block.value).url} target="_blank">
                {getBlockUrl(block.value).label}
              </a>
            </div>
          ) : (
            <div key={block.id}>
              <img src={getImageUrl(block.value)} />
            </div>
          );
        })
      )}
      <div className="buttons">
        {data.buttons.map((button) => (
          <button className={button.type} onClick={() => clickHandler(button)}>
            {button.type === "success" && <span>&#10003;</span>} {button.name}
          </button>
        ))}
        {data.type === "step" ? (
          <button className="unclear" onClick={() => setUnclear(data.id)}>
            &#215; Непонятно
          </button>
        ) : null}
      </div>
    </div>
  );
};
