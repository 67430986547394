interface StarProps {
  selected: boolean;
  onClick: () => void;
}

export const Star: React.FC<StarProps> = ({ selected, onClick }) => (
  <span
    className={selected ? "star selected" : "star"}
    onClick={onClick}
    role="button"
  >
    {selected ? "★" : "☆"}
  </span>
);
